export const planeFragmentShader = `
    uniform vec3 color1;
    uniform vec3 color2;
    uniform float delta;

    varying vec2 vUv;
    varying vec3 vPos;

    float random( vec2 p ) {
        vec2 K1 = vec2(
            23.14069263277926, // e^pi (Gelfond's constant)
            2.665144142690225 // 2^sqrt(2) (Gelfondâ€“Schneider constant)
        );
        return fract( cos( dot(p,K1) ) * 12345.6789 );
    }
    void main() {
        vec3 c = mix(color1, color2, sin(vPos.z * 0.1 + 1.95 * delta)) ;
        c.rgb += random(vUv)*0.55;
        gl_FragColor = vec4(c.rgb, 1.0);
    }
`;