import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';

const breakPoints = {
  sm: 320,
  md: 800,
  lg: 1200,
  xl: 1940,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: ${props => props.height || '100%'};
  @media screen and (min-width: ${breakPoints.sm}px) {
    width: 100%;
  }
  @media screen and (min-width: ${breakPoints.md}px) {
    max-width: 788px;
  }
  @media screen and (min-width: ${breakPoints.lg}px) {
    max-width: 1088px;
  }
  @media screen and (min-width: ${breakPoints.xl}px) {
    max-width: 1920px;
  }
`;
Container.displayName = 'Container';


const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center; */
  min-height: 280px;
  padding-top: 40px;
  /*
  @media screen and (min-width: ${breakPoints.sm}px) {
    min-height: 220px;
    padding-top: 20px;
  }
  @media screen and (min-width: ${breakPoints.md}px) {
    min-height: 280px;
    padding-top: 40px;
  }
  */
  @media screen and (min-width: ${breakPoints.lg}px) {
    min-height: 340px;
    padding-top: 60px;
  }
  @media screen and (min-width: ${breakPoints.xl}px) {
    max-width: 1920px;
  }
`;


const Headline = styled(Link)`
  text-decoration: none;
  font-size: 1.5rem;
  font-family: 'Press Start 2P', monospace;
  color: #f9cd9d;
  margin-top: 5px;
  font-weight: bold;
  padding: 15px 20px;
  /*
  @media screen and (min-width: ${breakPoints.sm}px) {
    font-size: 1.5rem;
  }
  */
  @media screen and (min-width: ${breakPoints.md}px) {
    font-size: 2.0rem;
  }
  @media screen and (min-width: ${breakPoints.lg}px) {
    font-size: 2.5rem;
    padding: 20px 30px;
  }
  @media screen and (min-width: ${breakPoints.xl}px) {
    max-width: 1920px;
  }
`;


const SubHeader = styled.div`
  /* color: #FF6AC1; */
  padding: 0px 8px;
  font-size: 1.0rem;
  /*transform: skewY(1deg);*/
  /*
  @media screen and (min-width: ${breakPoints.sm}px) {

  }
  @media screen and (min-width: ${breakPoints.md}px) {

  }
  */
  @media screen and (min-width: ${breakPoints.lg}px) {
    font-size: 1.24rem;
  }
  @media screen and (min-width: ${breakPoints.xl}px) {
    max-width: 1920px;
  }
`;

const Header = () => (
  <Hero>
    <Headline to="/"> Eric Gossett </Headline>
    <SubHeader> scientist | developer </SubHeader>
  </Hero>
);

export default Header;
