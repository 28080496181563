import React from 'react';
import styled from 'styled-components';
import pubList from './pubList';

const breakPoints = {
  sm: 320,
  md: 800,
  lg: 1200,
};

const Container = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 15px;
  height: ${props => props.height || '100%'};
  @media screen and (min-width: ${breakPoints.sm}px) {
    width: 100%;
  }
  @media screen and (min-width: ${breakPoints.md}px) {
    max-width: 788px;
  }
  @media screen and (min-width: ${breakPoints.lg}px) {
    max-width: 1088px;
  }
`;
Container.displayName = 'Container';

const Title = styled.h2`
  font-size: 1.0rem;
  margin: 0 0 23px;
  color: #57C7FF; /*#00e7c8;*/
  font-family: 'Press Start 2P', monospace;
  line-height: 1.5;
  text-decoration: none;
`;

const Authors = styled.p`
  font-size: 1.0rem;
  font-weight: 400;
  margin: 0 0 12px;
`;

const Abstract = styled.p`
  font-size: 1.0rem;
  margin: 0px;
  line-height: 1.5rem;
`;

const Journal = styled.h3`
  font-style: italic;
  font-size: 0.9rem;
  font-weight: 300;
  margin: 0 0 23px;
`;

const Publications = () => (
  <Container>
    {
      pubList.map(pub => (
        <div style={{
          marginBottom: '70px',
          backgroundColor: 'rgba(0, 19, 53, 0.5)',
          padding: '15px',
          borderRadius: '3px',
          border: '1px solid #7f82e2',
        }}
        >
          <a
            style={{ color: 'inherit', textDecoration: 'none' }}
            href={pub.doi}
          >
            <Title> {pub.title} </Title>
          </a>
          <Authors>
            {
              pub.authors.map((d) => {
                if (d === 'Eric Gossett') {
                  return <b> {d} </b>;
                }
                return d;
              }).reduce((prev, curr) => [prev, ', ', curr])
            }
          </Authors>
          <Journal>{pub.journal} ({pub.year})</Journal>
          <Abstract> {pub.abstract} </Abstract>
        </div>
      ))
    }
  </Container>
);

export default Publications;
