export const planeVertexShader = `
    uniform float delta;

    varying vec3 vPos;
    varying vec2 vUv;

    const float amp = 30.0;
    const float lambda = 1. / 3.14 * 20.0;
    const float size = 15.0;

    void main() {
        vUv = uv;
        vec3 p = position;
        vPos = position;
        p.z = sin(position.x * lambda + delta) * cos(position.y * lambda  + delta) * amp;
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_PointSize = size * ( 300.0 / -mvPosition.z );
        gl_Position = projectionMatrix * modelViewMatrix * vec4( p, 1.0 );   
    }
`;