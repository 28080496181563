import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import './navbar.css';
import initalsImage from '../../images/EG--blue.png';
import hamburger from '../../images/hamburger.png';
import closeX from '../../images/x.png';

const breakPoints = {
  sm: 320,
  md: 800,
  lg: 1200,
  xl: 1940,
};

const Container = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${breakPoints.sm}px) {
    width: 100%;
  }
  @media screen and (min-width: ${breakPoints.md}px) {
    max-width: 788px;
  }
  @media screen and (min-width: ${breakPoints.lg}px) {
    max-width: 1088px;
  }
  @media screen and (min-width: ${breakPoints.xl}px) {
    max-width: 1920px;
  }
`;
Container.displayName = 'Container';

const Overlay = () => (
  <div className="overlay">
    <Link to="/skills/" className="overlay__link" activeClassName="overlay__link--active"> Skills</Link>
    <Link to="/projects/" className="overlay__link" activeClassName="overlay__link--active"> Projects </Link>
    <Link to="/publications/" className="overlay__link" activeClassName="overlay__link--active"> Publications </Link>
  </div>
);



class Navbar extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      toggleNav: false
    };
    this.handleHamburgerClick = this.handleHamburgerClick.bind(this);
  }

  handleHamburgerClick() {
    this.setState(prevState => ({
      toggleNav: !prevState.toggleNav
    }));
  }

  render() {
    const isNavOverlayVisible = this.state.toggleNav;
    return (
      <>
        <nav id='navbar' className="navbar">
          <Container>
            <Link to="/" className="logo">
              <img src={initalsImage} style={{border:'0px', margin: '0px', height:'30px'}} />
            </Link>
            {/* Note order is reversed do to float left */}
            <Link to="/publications/" className="navbar__link" activeClassName="navbar__link--active"> Publications </Link>
            <Link to="/projects/" className="navbar__link" activeClassName="navbar__link--active"> Projects </Link>
            <Link to="/skills/" className="navbar__link" activeClassName="navbar__link--active"> Skills</Link>
            <a href="javascript:void(0);" className="navbar__hamburger" onClick={this.handleHamburgerClick}>
              {isNavOverlayVisible? (
              <img src={closeX} style={{border:'0px', margin: '0px', height:'30px'}} />
              ): (
                <img src={hamburger} style={{border:'0px', margin: '0px', height:'15px'}} />
              )
              }
            </a>
          </Container>
        </nav>
        {isNavOverlayVisible &&
          <Overlay />
        }
      </>
    );
  }

}

export default Navbar;
