import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const breakPoints = {
  sm: 320,
  md: 800,
  lg: 1200,
  xl: 1940,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: ${props => props.height || '100%'};
  padding: 0px 25px;
  @media screen and (min-width: ${breakPoints.sm}px) {
    width: 100%;
  }
  @media screen and (min-width: ${breakPoints.md}px) {
    max-width: 788px;
    padding: 0px 0px;

  }
  @media screen and (min-width: ${breakPoints.lg}px) {
    max-width: 1088px;
    padding: 0px 0px;
  }
  @media screen and (min-width: ${breakPoints.xl}px) {
    max-width: 1920px;
    padding: 0px 0px;
  }
`;
Container.displayName = 'Container';

const H1 = styled.div`
  font-size: 1.8rem;
  position: relative;
  display: inline-block;
  font-family: 'Press Start 2P', monospace;
  line-height: 1.5;
  z-index: 1;
  clear: both;
  margin-top:60px;
  color:   ${props => props.color};
  transform: skewY(-1deg);
  /*
  &:after {
    content: "";
    position: absolute;
    top: -30%;
    left: -15%;
    width: 130%;
    height: 170%;
    background: ${props => props.backgroundColor};
    box-shadow: -5px 5px ${props => props.boxShadowColor};
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
    z-index: -1;
  }
  */
  /*
  @media screen and (min-width: ${breakPoints.sm}px) {
    font-size: 2.0rem;
  }
  @media screen and (min-width: ${breakPoints.md}px) {
    font-size: 2.5rem;
    &:after {
    content: "";
    position: absolute;
    top: -15%;
    left: -10%;
    width: 120%;
    height: 160%;
    background: #DBEAFF;
    box-shadow: -5px 7px #C5CFE9;
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
    z-index: -1;
  }
  }
  @media screen and (min-width: ${breakPoints.lg}px) {
    font-size: 3.0rem;
    &:after {
    content: "";
    position: absolute;
    top: -50%;
    left: -10%;
    width: 120%;
    height: 200%;
    background: #DBEAFF;
    box-shadow: -5px 7px #C5CFE9;
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
    z-index: -1;
  }
  }
  */
`;

function Headline(props) {
  return (
    <Container>
      <span>
        {' '}
        <H1
          backgroundColor={props.backgroundColor}
          color={props.color}
          boxShadowColor={props.boxShadowColor}
        >
          {' '}
          {props.text}{' '}
        </H1>{' '}
      </span>
    </Container>
  );
}

Headline.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  boxShadowColor: PropTypes.string,
};

Headline.defaultProps = {
  backgroundColor: '#DBEAFF',
  color: '#5F6B9D',
  boxShadowColor: '#C5CFE9',
};

export default Headline;
