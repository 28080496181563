import React from 'react';
import styled from 'styled-components';
import * as THREE from 'three';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { GlitchPass } from './postProcessing/CustomGlitchPass';

// import { sphereVertexShader } from './shaders/sphereVertexShader';
// import { sphereFragmentShader } from './shaders/sphereFragmentShader';


import { planeVertexShader } from './shaders/planeVertexShader';
import { planeFragmentShader } from './shaders/planeFragmentShader';

import { pointsFragmentShader } from './shaders/pointsFragmentShader';


const Overlay = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 1;
  overflow-y: scroll;
  /* hide scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

class WebGLBackground extends React.Component {
  componentDidMount() {
    const WIDTH = window.innerWidth;
    const HEIGHT = window.innerHeight;

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(WIDTH, HEIGHT);
    this.renderer.setClearColor(0x000000, 0);
    this.mount.appendChild(this.renderer.domElement);
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      10000,
    );
    this.camera.position.set(0, 0, 1000);
    this.scene.add(this.camera);



    /*
        Plane setup
    */

    const planeUniforms = {
      color1: {
        value: new THREE.Color(0x780078),
      },
      color2: {
        value: new THREE.Color(0x450045).multiplyScalar(1.25),
      },
      delta: {
        value: 0.0,
      },
    };

    this.planeMaterial = new THREE.ShaderMaterial({
      uniforms: planeUniforms,
      vertexShader: planeVertexShader,
      fragmentShader: planeFragmentShader,
      wireframe: true,
      // wireframeLinewidth: 0.5,
    });

    const planeGeometry = new THREE.PlaneGeometry(1200, 800, 10, 10);

    //  var material = new THREE.MeshBasicMaterial( { color: 0x0000ff } );

    const planeYPos = -400;

    const plane = new THREE.Mesh(planeGeometry, this.planeMaterial);
    plane.material.side = THREE.DoubleSide;
    plane.rotation.x = -30;
    plane.position.y = planeYPos;
    plane.position.z = 100;
    this.scene.add(plane);


    const pointsUniforms = {
      color: {
        value: new THREE.Color(0xff77ff),
      },
      delta: {
        value: 0.0,
      },
    };

    this.pointsMaterial = new THREE.ShaderMaterial({
      uniforms: pointsUniforms,
      vertexShader: planeVertexShader,
      fragmentShader: pointsFragmentShader,
      // wireframeLinewidth: 0.5,
    });

    const pointsGeometry = new THREE.BufferGeometry();
    const { vertices } = planeGeometry;
    const positions = new Float32Array(vertices.length * 3);
    let vertex;
    for (let i = 0, l = vertices.length; i < l; i++) {
      vertex = vertices[i];
      vertex.toArray(positions, i * 3);
    }

    pointsGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    const particles = new THREE.Points(pointsGeometry, this.pointsMaterial);
    particles.position.set(
      plane.position.x,
      plane.position.y,
      plane.position.z,

    );
    particles.rotation.x = -30;
    particles.position.y = planeYPos;
    particles.position.z = 100;
    this.scene.add(particles);


    /*
      post processing
    */

    this.composer = new EffectComposer(this.renderer);

    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);

    const glitchPass = new GlitchPass();
    this.composer.addPass(glitchPass);
    glitchPass.enabled = true;

    setTimeout(() => {
      glitchPass.enabled = false;
    }, 1000);


    const triggerGlitch = () => {
      glitchPass.goWild = true;
      const _ = setTimeout(() => {
        glitchPass.goWild = false;
      }, 50);
    };


    this.onWindowResize = () => {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    window.addEventListener('resize', this.onWindowResize, false);

    // How to animate
    this.start = Date.now();

    this.animate();
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    document.removeEventListener('mousedown', this.mouseDown);
    document.removeEventListener('mouseup', this.mouseUp);

    window.cancelAnimationFrame(this.requestID);
    this.mount.removeChild(this.renderer.domElement)
  }


  animate = () => {
    // Update uniforms
    this.planeMaterial.uniforms.delta.value = 0.001 * (Date.now() - this.start);
    this.pointsMaterial.uniforms.delta.value = 0.001 * (Date.now() - this.start);

    this.requestID = requestAnimationFrame(this.animate);
    this.composer.render(this.scene, this.camera);
  }

  render() {
    return (
      <div ref={ref => (this.mount = ref)}> 
        <Overlay>
          { this.props.children }
        </Overlay>
      </div>
    );
  }
}

export default WebGLBackground;
