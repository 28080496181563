import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Helemt from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { StaticQuery, graphql, Link } from 'gatsby';
import WebGLBackground from '../WebGLWaves';
import Navbar from '../Navbar';

import SEO from '../SEO';



const Nav = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100%;
`;

const Logo = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  text-align: center;
  margin-top: 25px;
  font-size: 12px;
  font-family: 'Michroma', sans-serif;
`;

const NavContainer = styled.div`
  padding: 30px 0px;
  margin: 0px auto;
  max-width: 1100px;
  display: flex;
`;

const Layout = ({ children }) => (
  <>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
      <>
          <SEO />
        <Navbar />
        <WebGLBackground>
            <main style={ {marginTop: '-30px'} }>
              {children}
            </main>
        </WebGLBackground>
      </>
      )}
    />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

