import React from 'react';
import styled from 'styled-components';


const FooterContainer = styled.div`
  margin: 30px;
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
`;

const FooterText = styled.div`
  color: #f9cd9d;
  padding: 4px 8px;
  border: 1px solid #f9cd9d;
  font-size: 0.8rem;
`;

const year = new Date().getFullYear();

const Footer = () => (
  <FooterContainer>
    <FooterText>  ©{ year } </FooterText>
  </FooterContainer>
);


export default Footer;
