export const pointsFragmentShader = `
    uniform vec3 color;
    uniform float delta;

    varying vec2 vUv;
    float random( vec2 p ) {
        vec2 K1 = vec2(
            23.14069263277926, // e^pi (Gelfond's constant)
            2.665144142690225 // 2^sqrt(2) (Gelfondâ€“Schneider constant)
        );
        return fract( cos( dot(p,K1) ) * 12345.6789 );
    }
    void main() {
        vec3 c = color;
        c.rgb += random(vUv)*0.55;
        gl_FragColor = vec4(c.rgb, 1.0);
    }
`;