import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Link from 'gatsby-link';


const Container = styled.div`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
  border: 1px solid #7f82e2;
  border-radius: 3px;
  background-color: rgba(0, 19, 53, 0.5);

`;
Container.displayName = 'Container';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  padding: 20px 20px 16px 20px;
`;
Frame.displayName = 'Frame';

const Headline = styled.h2`
  font-family: 'Press Start 2P', monospace;
  line-height: 1.5;
  color: #57C7FF; /*#00E7C8;*/
  font-size: 20px;
  margin: 0 0 23px;
`;
Headline.displayName = 'Headline';

const Body = styled.p`
  font-family: 'Source Code Pro', monospace;
  font-size: 1rem;
  margin: 0px;
`;
Body.displayName = 'Body';

const ButtonPanel = styled.div`
  font-family: 'Source Code Pro', monospace;
  display: flex;
  padding: 16px 20px;
`;
ButtonPanel.displayName = 'ButtonPanel';

const Button = styled(Link)`
  padding: 12px 30px;
  border: 1px solid;
  border-color: ${props => props.background || ' #FF6AC1'};
  font-size: 16px;
  color: ${props => props.color || ' #FF6AC1'};
  text-decoration: none;
  /*box-shadow: -3px 5px 0px  #1A1819;*/
  &:hover {
    /*
    color: ${props => props.colorHover || '#686868'};
    border-color: ${props => props.borderColorHover || '#686868'};
    */
    /*box-shadow: -1px 3px 0px  #1A1819; */
  }
  &:active {
    transform: scale(0.98);
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
`;
Button.displayName = 'Button';

// add after <Container> For an image
// {props.imageSizes !== null && <Img sizes={props.imageSizes} />}

function Card(props) {
  return (
    <Container>
      <Frame>
        <Headline> {props.headline} </Headline>
        <Body>{props.body}</Body>
      </Frame>
      <ButtonPanel>
        <Button to={props.linkTo}> {props.buttonText} </Button>
      </ButtonPanel>
    </Container>
  );
}

Card.propTypes = {
  headline: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  // imageSizes: PropTypes.objectOf(PropTypes.object),
  linkTo: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

Card.defaultProps = {
  // imageSizes: null,
};

export default Card;
