import React from 'react';
import styled from 'styled-components';

import './devicons/devicon.min.css';
import tensorflowLogo from './icons/tensorflow.svg';
import kerasLogo from './icons/keras.svg';
import pytorchLogo from './icons/pytorch.svg';
import sklearnLogo from './icons/sklearn.svg';
import scipyLogo from './icons/scipy.svg';
import flaskLogo from './icons/flask.svg';
import reduxLogo from './icons/redux.svg';
import rustLogo from './icons/rust.svg';
import threejsLogo from './icons/threejs.svg';


const breakPoints = {
  sm: 320,
  md: 800,
  lg: 1200,
  xl: 1940,
};


const Container = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: ${props => props.height || '100%'};
  @media screen and (min-width: ${breakPoints.sm}px) {
    width: 100%;
  }
  @media screen and (min-width: ${breakPoints.md}px) {
    max-width: 788px;
  }
  @media screen and (min-width: ${breakPoints.lg}px) {
    max-width: 1088px;
  }
  @media screen and (min-width: ${breakPoints.xl}px) {
    max-width: 1920px;
  }
`;
Container.displayName = 'Container';

const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
  /*margin-bottom: 30px;*/
`;

const IconItem = styled.div`
  flex: 1 1 20%;
  height: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);

  & > i {
    color: #7f82e2;
    font-size: 42px;
  }
  & > h4 {
    color: #dfdee1;
    font-size: 14px;
    margin-top: 18px;
    font-family: 'IBM Plex Mono', monospace;
  }
`;

const CustomIcon = styled.img`
  width: 42px;
  height: 42px;
  margin: 0 auto;
  border: 0px;
`;


const Category = styled.h2`
  font-size: 1.0rem;
  margin: 0 0 60px;
  color: #57C7FF; /*#00e7c8;*/
  font-family: 'Press Start 2P',monospace;
  line-height: 1.5;
`;

const Skills = () => (
  <Container>
    <Category> Languages </Category>
    <Icons>
      <IconItem>
        <i className="devicon-javascript-plain" />
        <h4> JavaScript </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-typescript-plain" />
        <h4> TypeScript </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-python-plain" />
        <h4> Python </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-go-plain" />
        <h4> Go </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-swift-plain" />
        <h4> Swift </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-html5-plain" />
        <h4> HTML </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-css3-plain" />
        <h4> CSS </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-c-plain" />
        <h4> C </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-cplusplus-plain" />
        <h4> C++ </h4>
      </IconItem>
      <IconItem>
        <CustomIcon src={rustLogo} />
        <h4> Rust </h4>
      </IconItem>
    </Icons>

    <Category> Webdev </Category>
    <Icons>

      <IconItem>
        <i className="devicon-react-plain" />
        <h4> React </h4>
      </IconItem>
      <IconItem>
        <CustomIcon src={reduxLogo} />
        <h4> Redux </h4>
      </IconItem>
      <IconItem>
        <CustomIcon src={threejsLogo} />
        <h4> Three.js </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-d3js-plain" />
        <h4> D3 </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-nodejs-plain" />
        <h4> Node </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-express-original" />
        <h4> Express </h4>
      </IconItem>
      <IconItem>
        <CustomIcon src={flaskLogo} />
        <h4> Flask </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-django-plain" />
        <h4> Django </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-postgresql-plain" />
        <h4> Postgres </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-mongodb-plain" />
        <h4> MongoDB </h4>
      </IconItem>
    </Icons>


    <Category> Data Science </Category>
    <Icons>
      <IconItem>
        <CustomIcon src={tensorflowLogo} />
        <h4> Tensorflow </h4>
      </IconItem>
      <IconItem>
        <CustomIcon src={pytorchLogo} />
        <h4> PyTorch </h4>
      </IconItem>
      <IconItem>
        <CustomIcon src={kerasLogo} />
        <h4> Keras </h4>
      </IconItem>
      <IconItem>
        <CustomIcon src={sklearnLogo} />
        <h4> Scikit-Learn </h4>
      </IconItem>
      <IconItem>
        <CustomIcon src={scipyLogo} />
        <h4> SciPy </h4>
      </IconItem>
    </Icons>

    <Category> Dev Ops </Category>
    <Icons>
      <IconItem>
        <i className="devicon-linux-plain" />
        <h4> Linux </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-docker-plain" />
        <h4> Docker </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-nginx-plain" />
        <h4> NGINX </h4>
      </IconItem>
      <IconItem>
        <i class="devicon-travis-plain"></i>
        <h4> Travis CI </h4>
      </IconItem>
      <IconItem>
        <i class="devicon-amazonwebservices-original"></i>
        <h4> AWS </h4>
      </IconItem>
    </Icons>

    <Category> Mobile </Category>
    <Icons>
      <IconItem>
        <i className="devicon-react-plain" />
        <h4> React Native </h4>
      </IconItem>
      <IconItem>
        <i className="devicon-apple-plain" />
        <h4> iOS </h4>
      </IconItem>
    </Icons>

  </Container>
);

export default Skills;
