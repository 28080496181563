const publications = [
  {
    title: 'Predicting Superhard Materials via a Machine Learning Informed Evolutionary Structure Search',
    authors: [
      'Patrick Avery',
      'Xiaoyu Wang',
      'Corey Oses',
      'Eric Gossett',
      'Davide M. Proserpio',
      'Cormac Toher',
      'Stefano Curtarolo',
      'Eva Zurek',
    ],
    journal: 'NPJ Computational Materials',
    year: 2019,
    abstract: 'Good agreement was found between experimental Vickers hardnesses, Hv, of a wide range of materials and those calculated by three macroscopic hardness models that employ the shear and/or bulk moduli obtained from: (i) first principles via AFLOW-AEL (AFLOW Automatic Elastic Library), and (ii) a machine learning (ML) model trained on materials within the AFLOW repository. Because HMLv values can be quickly estimated, they can be used in conjunction with an evolutionary search to predict stable, superhard materials. This methodology is implemented in the XtalOpt evolutionary algorithm. Each crystal is minimized to the nearest local minimum, and its Vickers hardness is computed via a linear relationship with the shear modulus discovered by Teter. Both the energy/enthalpy and HMLv, Teter are employed to determine a structures fitness. This implementation is applied towards the carbon system, and 43 new superhard phases are found. A topological analysis reveals that phases estimated to be slightly harder than diamond contain a substantial fraction of diamond and/or lonsdaleite.',
    doi: 'http://dx.doi.org/10.1038/s41524-019-0226-8',
  },
  {
    title: 'The AFLOW Library of Crystallographic Prototypes: Part 2',
    authors: [
      'David Hicks',
      'Michael J. Mehl',
      'Eric Gossett',
      'Cormac Toher',
      'Ohad Levy',
      'Robert M. Hanson',
      'Gus Hart',
      'Stefano Curtarolo',
    ],
    journal: 'Computational Materials Science',
    year: 2018,
    abstract: 'Materials discovery via high-throughput methods relies on the availability of structural prototypes, which are generally decorated with varying combinations of elements to produce potential new materials. To facilitate the automatic generation of these materials, we developed The AFLOW Library of Crystallographic Prototypes — a collection of crystal prototypes that can be rapidly decorated using the AFLOW software. Part 2 of this work introduces an additional 302 crystal structure prototypes, including at least one from each of the 138 space groups not included in Part 1. Combined with Part 1, the entire library consists of 590 unique crystallographic prototypes covering all 230 space groups. We also present discussions of enantiomorphic space groups, Wigner-Seitz cells, the two-dimensional plane groups, and the various different space group notations used throughout crystallography. All structures — from both Part 1 and Part 2 — are listed in the web version of the library available at http://www.aflow.org/CrystalDatabase.',
    doi: 'http://dx.doi.org/10.1016/j.commatsci.2018.10.043',
  },
  {
    title: 'The AFLOW Fleet for Materials Discovery',
    authors: [
      'Cormac Toher',
      'David Hicks',
      'Corey Oses',
      'Eric Gossett',
      'et. al',
    ],
    journal: 'Handbook of Materials Modeling',
    year: 2018,
    abstract: 'The traditional paradigm for materials discovery has been recently expanded to incorporate substantial data-driven research. With the intent to accelerate the development and the deployment of new technologies, the AFLOW Fleet for computational materials design automates high-throughput first-principles calculations and provides tools for data verification and dissemination for a broad community of users. AFLOW incorporates different computational modules to robustly determine thermodynamic stability, electronic band structures, vibrational dispersions, thermomechanical properties, and more. The AFLOW data repository is publicly accessible online at aflow.org, with more than 1.8 million materials entries and a panoply of queryable computed properties. Tools to programmatically search and process the data, as well as to perform online machine learning predictions, are also available.',
    doi: 'http://dx.doi.org/10.1007/978-3-319-42913-7_63-1',
  },
  {
    title: 'AFLOW-CHULL: Cloud-Oriented Platform for Autonomous Phase Stability Analysis',
    authors: [
      'Corey Oses',
      'Eric Gossett',
      'David Hicks',
      'Frisco Rose',
      'Michael J. Mehl',
      'Eric Perim',
      'Ichiro Takeuchi',
      'Stefano Sanvito',
      'Matthias Scheffler',
      'Yoav Lederer',
      'Ohad Levy',
      'Cormac Toher',
      'Stefano Curtarolo',
    ],
    journal: 'Journal of Chemical Infomation and Modeling',
    year: 2018,
    abstract: 'A priori prediction of phase stability of materials is a challenging practice, requiring knowledge of all energetically-competing structures at formation conditions. Large materials repositories - housing properties of both experimental and hypothetical compounds - offer a path to prediction through the construction of informatics-based, ab-initio phase diagrams. However, limited access to relevant data and software infrastructure has rendered thermodynamic characterizations largely peripheral, despite their continued success in dictating synthesizability. Herein, a new module is presented for autonomous thermodynamic stability analysis, implemented within the open-source, ab-initio framework AFLOW. Powered by the AFLUX Search-API, AFLOW-CHULL leverages data of more than 1.8 million compounds characterized in the AFLOW.org repository, and can be employed locally from any UNIX-like computer. The module integrates a range of functionality: the identification of stable phases and equivalent structures, phase coexistence, measures for robust stability, and determination of decomposition reactions. As a proof-of-concept, thermodynamic characterizations have been performed for more than 1,300 binary and ternary systems, enabling the identification of several candidate phases for synthesis based on their relative stability criterion - including 18 promising C15b-type structures and two half-Heuslers. In addition to a full report included herein, an interactive, online web application has been developed showcasing the results of the analysis, and is located at aflow.org/aflow-chull.',
    doi: 'https://doi.org/10.1021/acs.jcim.8b00393',
  },
  {
    title: 'AFLOW-ML: A RESTful API for machine-learning predictions of materials properties',
    authors: [
      'Eric Gossett',
      'Cormac Toher',
      'Corey Oses',
      'Olexandr Isayev',
      'Fleur Legrain',
      'Frisco Rose',
      'Eva Zurek',
      'Jesús Carrete',
      'Natalio Mingo',
      'Alexander Tropsha',
      'Stefano Curtarolo',
    ],
    journal: 'Computational Materials Science',
    year: 2018,
    abstract: 'Machine learning approaches, enabled by the emergence of comprehensive databases of materials properties, are becoming a fruitful direction for materials analysis. As a result, a plethora of models have been constructed and trained on existing data to predict properties of new systems. These powerful methods allow researchers to target studies only at interesting materials – neglecting the non-synthesizable systems and those without the desired properties – thus reducing the amount of resources spent on expensive computations and/or time-consuming experimental synthesis. However, using these predictive models is not always straightforward. Often, they require a panoply of technical expertise, creating barriers for general users. AFLOW-ML (AFLOW Machine Learning) overcomes the problem by streamlining the use of the machine learning methods developed within the AFLOW consortium. The framework provides an open RESTful API to directly access the continuously updated algorithms, which can be transparently integrated into any workflow to retrieve predictions of electronic, thermal and mechanical properties. These types of interconnected cloud-based applications are envisioned to be capable of further accelerating the adoption of machine learning methods into materials development.',
    doi: 'https://doi.org/10.1016/j.commatsci.2018.03.075',
  },
  {
    title: 'AFLOW-SYM: platform for the complete, automatic and self-consistent symmetry analysis of crystals',
    authors: [
      'David Hicks',
      'Corey Oses',
      'Eric Gossett',
      'Geena Gomez',
      'Richard H. Taylor',
      'Cormac Toher',
      'Michael J. Mehl',
      'Ohad Levy',
      'Stefano Curtarolo',
    ],
    journal: 'Acta Crystallographica Section A: Foundations and Advances',
    year: 2018,
    abstract: 'Determination of the symmetry profile of structures is a persistent challenge in materials science. Results often vary amongst standard packages, hindering autonomous materials development by requiring continuous user attention and educated guesses. This article presents a robust procedure for evaluating the complete suite of symmetry properties, featuring various representations for the point, factor and space groups, site symmetries and Wyckoff positions. The protocol determines a system‐specific mapping tolerance that yields symmetry operations entirely commensurate with fundamental crystallographic principles. The self‐consistent tolerance characterizes the effective spatial resolution of the reported atomic positions. The approach is compared with the most used programs and is successfully validated against the space‐group information provided for over 54,000 entries in the Inorganic Crystal Structure Database (ICSD). Subsequently, a complete symmetry analysis is applied to all 1.7+ million entries of the AFLOW data repository. The AFLOW‐SYM package has been implemented in, and made available for, public use through the automated ab initio framework AFLOW.',
    doi: 'https://doi.org/10.1107/S2053273318003066',
  },
  {
    title: 'Universal fragment descriptors for predicting properties of inorganic crystals',
    authors: [
      'Olexandr Isayev',
      'Corey Oses',
      'Cormac Toher',
      'Eric Gossett',
      'Stefano Curtarolo',
      'Alexander Tropsha',
    ],
    journal: 'Nature Communications',
    year: 2017,
    abstract: 'Although historically materials discovery has been driven by a laborious trial-and-error process, knowledge-driven materials design can now be enabled by the rational combination of Machine Learning methods and materials databases. Here, data from the AFLOW repository for ab initio calculations is combined with Quantitative Materials Structure-Property Relationship models to predict important properties: metal/insulator classification, band gap energy, bulk/shear moduli, Debye temperature and heat capacities. The prediction’s accuracy compares well with the quality of the training data for virtually any stoichiometric inorganic crystalline material, reciprocating the available thermomechanical experimental data. The universality of the approach is attributed to the construction of the descriptors: Property-Labelled Materials Fragments. The representations require only minimal structural input allowing straightforward implementations of simple heuristic design rules.',
    doi: 'https://doi.org/10.1038/ncomms15679',
  },
  {
    title: 'AFLUX: The LUX materials search API for the AFLOW data repositories',
    authors: [
      'Frisco Rose',
      'Cormac Toher',
      'Eric Gossett',
      'Corey Oses',
      'Marco Buongiorno Nardelli',
      'Marco Fornari',
      'Stefano Curtarolo',
    ],
    journal: 'Computational Materials Science',
    year: 2017,
    abstract: 'Automated computational materials science frameworks rapidly generate large quantities of materials data for accelerated materials design. In order to take advantage of these large databases, users should have the ability to efficiently search and extract the desired data. Therefore, we have extended the data-oriented AFLOW-repository Application-Program-Interface (API) (Comput. Mater. Sci. 93, 178 (2014)) to enable programmatic access to search queries. A Uniform Resource Identifier (URI)-based search API is proposed for the construction of complex queries for remote creation and retrieval of customized data sets. It is expected that the new language, AFLUX, from “Automatic Flow of LUX (light)”, will enable remote search operations on the AFLOW set of computational materials science data repositories. In addition, AFLUX facilitates the verification and validation of the data in the AFLOW repositories.',
    doi: 'https://doi.org/10.1016/j.commatsci.2017.04.036',
  },
  {
    title: 'Computational Nanocharacterization for Combinatorially Developed Bulk Metallic Glass',
    authors: [
      'Eric Gossett',
      'Ellen B. Scanley',
      'Yanhui Liu',
      'Yanglin Li',
      'Ze Liu',
      'Sungwoo Sohn',
      'Jan Schroers',
      'Christine Broadbridge',
      'Todd C. Schwendemann',
    ],
    journal: 'International Journal of High Speed Electronics and Systems',
    year: 2015,
    abstract: 'Bulk metallic glasses synthesized at specialized facilities at Yale using magnetron cosputtering are sent to Southern Connecticut State University for elemental characterization. Characterization is done using a Zeiss Sigma VP SEM coupled with an Oxford EDS. Characterization is automated using control software provided by Oxford. Collected data is processed and visualized using computational methods developed internally. Processed data is then organized into a database suitable for web retrieval. This technique allows for the rapid characterization of a combinatorial wafer to be carried out in ~11 hours for a single wafer containing ~600 unique compounds.',
    doi: 'https://doi.org/10.1142/S0129156415200128',
  },
];


export default publications;
